@charset "utf-8";

/* CSS Document */

@font-face {
    font-family: "Point Book";
    src: url("../fonts/Point-Book.woff2") format("woff2"), url("../fonts/Point-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "Point";
    src: url("../fonts/Point-Regular.woff2") format("woff2"), url("../fonts/Point-Regular.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
/* SWEET ALERT */
.swal2-title {
    font-weight: 300;

}

/* Body Default */

body {
    font-family: "Point";
    font-weight: normal;
}

body img {
    max-width: 100%;
    height: auto;
}

ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

/*   ABOUT +BLOGS */
.pst_meta {
    font-size: 14px;
    color: #fff;
    padding: 10px;
    position: absolute;
    bottom: 0;
}

.pst_meta span svg {
    width: 13px;
    height: 13px;
    margin-right: 3px;
}



/* BG REMOVER */

/* //COLOR PICKER */
.CirclePicker_picker {
    background-color: rgb(236 236 236);
    border-radius: 8px;
    display: inline-block;
    padding: 8px;
    height: 100%;
}

.html_colors .CirclePicker_picker .circle-picker {
    width: auto !important;
    justify-content: space-evenly;
}

.html_colors .chrome-picker {
    width: auto !important;
    height: 100%;
}

/* Custom scrollbar */

.cstm_scrollbar::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    /* border-radius: 0.5rem !important; */
}

.cstm_scrollbar::-webkit-scrollbar-track {
    background: #333333;
    /* background: #f1f1f1; */
    /* border-radius: 0.5rem !important; */
}

.cstm_scrollbar::-webkit-scrollbar-thumb {
    background: #333333;
    /* background: #c6c3c6; */
    /* border-radius: 0.5rem !important; */
}

.cstm_scrollbar::-webkit-scrollbar-thumb:hover {
    background: #2ca876;
    outline: 0;
}

.cstm_scrollbar::-webkit-scrollbar-thumb:hover .ui-menu .ui-menu-item-wrapper:focus {
    outline: 0;
    border: 0;
}

.cstm_scrollbar::-webkit-scrollbar-thumb:hover .ui-menu .ui-menu-item-wrapper:hover {
    outline: 0;
    border: 0;
}

.text_left {
    text-align: left;
}

.text_right {
    text-align: right;
}

a.btn {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

a.btn:hover {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

button.btn {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

button.btn:hover {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}


/* App Header Style  */

.app_top_nav {
    background: #333333;
    color: #fff;
    padding: 21.5px 20px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
}

.app_logo {
    width: 280px;
}

.app_logo img {
    max-width: 100%;
    width: 200px;
    height: 30px;

}

.app_other_links {
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.tp_search {
    position: relative;
    max-width: 200px;
}

.tp_search input {
    /* height: 32px; */
    border-radius: 4px;
    padding-left: 30px;
    color: #333333;
}

.tp_search input:-ms-input-placeholder {
    color: #bfbfbf;
}

.tp_search input::-webkit-input-placeholder {
    color: #bfbfbf;
}

.tp_search input:focus {
    box-shadow: 0 0 0 0.25rem rgb(44 168 118 / 25%);
    border-color: #2ca876;
}

.search_icn {
    position: absolute;
    top: 5px;
    left: 10px;
}

.search_icn svg {
    width: 16px;
    height: 16px;
    pointer-events: none;
}

.tp_ul {
    display: flex;
    align-items: center;
}

.tp_ul li {
    margin-left: 20px;
}

.tp_ul li:last-child {
    margin-left: 0 !important;
}

.tp_ul li:nth-last-child(2) {
    margin-left: 15px;
}

.tp_ul li a {
    color: #bfbfbf;
    text-decoration: none;
    font-size: 16px;
}

.tp_ul li:nth-last-child(-n+2) a {
    border-left: 1px solid #e5e7eb;
    padding: 0 15px;
}

.toggle_bar {
    width: 18px;
    margin: 10px 0;
    border-radius: 4px;
    position: relative;
    border-top: 3px solid #fff;
    display: block;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.toggle_bar::before,
.toggle_bar::after {
    content: "";
    display: block;
    background: #fff;
    height: 3px;
    width: 24px;
    border-radius: 4px;
    position: absolute;
    top: -10px;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform-origin: 13%;
    -webkit-transform-origin: 13%;
    transform-origin: 13%;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.toggle_bar::after {
    top: 4px;
}


/* Opened Menu */

.openedMenu .toggle_bar {
    border-color: #333333;
}

.openedMenu .toggle_bar::before {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #fff;
}

.openedMenu .toggle_bar::after {
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background: #fff;
}

.openedMenu .toggle_bar::after {
    top: 2.2px;
    left: 0.4px;
}

.small_view_head {
    display: none;
}

.small_view .small_view_head {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 7px;
    padding: 4px 0;
}

.small_view .app_other_links {
    display: none;
}

.ssearch_icn svg {
    width: 20px;
    height: 20px;
}


.ssearch_icn svg path {
    fill: #fff;
}

.hamburger_icn {
    display: inline-block;
    cursor: pointer;
}

.ssearch_icn {
    margin-right: 8px;
    cursor: pointer;
}

.search_bx_overlay {
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

}




.search_bx_overlay .tp_search {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.search_bx_overlay input {
    max-width: 290px;
}


/* Main Wrapper */

.main_panel {
    transition: width 0.25s ease, margin 0.25s ease;
    width: calc(100% - 265px);
    min-height: calc(100vh - 79px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}


/* Content Wrapper */

.content_wrapper {
    padding: 15px;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    margin-left: 265px;
    width: 100%;
    margin-top: 75px;
}


/* SideNav */

.sidenav {
    width: 265px;
    background: #333333;
    height: 100%;
    /* display: block; */
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 74px;
    overflow-y: scroll;
    padding-bottom: 75px;
}




.side_ul li {
    margin-bottom: -10px;
    padding: 10px 8px;
}

.side_ul li a {
    color: #bfbfbf;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px 10px;
    border-radius: 4px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.side_ul li a:hover {
    background: #fff;
    color: #333333;
}

.side_ul li a.active {
    background: #fff;
    color: #333333;
}

.side_ul li a:hover svg path {
    fill: #333333;
}

.side_ul li a:hover::after {
    border-color: #333333;
}

.side_ul li a::after {
    position: absolute;
    content: "";
    width: 0.5em;
    height: 0.5em;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #bfbfbf;
    right: 15px;
    -webkit-transform: rotate(45deg) translate(0, -50%);
    -ms-transform: rotate(45deg) translate(0, -50%);
    -o-transform: rotate(45deg) translate(0, -50%);
    transform: rotate(45deg) translate(0, -50%);
    transform-origin: top;
    top: 46%;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}



.side_ul li a.no_after_effect::after {
    /* Exclude after-effect for elements with class 'no_after_effect' */
    display: none; /* Or any other styles to override or remove the after-effect */
}


.side_ul li a.active {
    background: #fff;
    color: #333333;
}

.side_ul li a.active svg path {
    fill: #333333;
}

.side_ul li a.active::after {
    border-color: #333333;
}

.side_ul li a.active::after {
    -webkit-transform: rotate(225deg) translate(0, -50%);
    -ms-transform: rotate(225deg) translate(0, -50%);
    -o-transform: rotate(225deg) translate(0, -50%);
    transform: rotate(225deg) translate(0, -50%);
    top: 23px;
}

.side_ul_in li a::after {
    display: none;
}

.side_ul li a span {
    display: inline-block;
}

.cat_title {
    margin-top: 3px;
    margin-left: 5px;
}

.side_ul_in {
    display: none;
}

.side_ul li.active .side_ul_in {
    display: block;
}

.side_ul_in li a {
    padding: 6px 10px;
    padding-bottom: 2px;
    font-size: 16px;
}

.small_view_ham .sidenav {
    left: 0;
    transition: 0.3s;
}
.overlay_dismiss {
    position: absolute;
    top: 25px;
    right: 20px;
    cursor: pointer;
}
.overlay_dismiss img {
    width: 24px;
}
/* DROPDOWN DESIGN */
.thumb {
    margin-top: 16px;
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
}

.thumbInner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
}

.img {
    display: block;
    width: auto;
    height: 100%;
}
.other_pages {
    margin-right: 30px;
    position: relative;
    cursor: pointer;
}

.other_pages::after {
    position: absolute;
    content: "";
    width: 0.6em;
    height: 0.6em;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #bfbfbf;
    right: -17px;
    top: 9px !important;
    -webkit-transform: rotate(45deg) translate(0, -50%);
    -ms-transform: rotate(45deg) translate(0, -50%);
    -o-transform: rotate(45deg) translate(0, -50%);
    transform: rotate(45deg) translate(0, -50%);
    transform-origin: top;
    top: 46%;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.other_pages.active::after {
    -webkit-transform: rotate(225deg) translate(0, -50%);
    -ms-transform: rotate(225deg) translate(0, -50%);
    -o-transform: rotate(225deg) translate(0, -50%);
    transform: rotate(225deg) translate(0, -50%);
    top: 15px !important;
}

.pages_drp {
    position: relative;
}

.pages_drp ul {
    background: #2ca876;
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    top: auto;
    right: 0;
    min-width: 131px;

    display: block;
}

.fadeOut {
    opacity: 0;

    transition: opacity 1s ease;

}

.fadeIn {
    transition: opacity 1s ease;

}

.pages_drp ul li {
    margin-bottom: 10px;
}

.pages_drp ul li:last-child {
    margin-bottom: 0;
}

.pages_drp ul li a {
    padding: 5px 10px;
    text-decoration: none;
    font-size: 16px;
    color: #333333;
    background: #fff;
    border-radius: 3px;
    width: 100%;
    display: block;
    text-align: center;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.pages_drp ul li a:hover {
    background: #333333;
    color: #fff;
}
/* Page Title */

.pg_title {
    text-align: center;
    margin-bottom: 24px;
}

.pg_title h2 {
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
}

.pg_title p {
    font-size: 16px;
    /* color: #acacac; */
     max-width: 900px; 
    margin: 0 auto;
}

/* Converters */

.convert_tool_wrapper {
    margin: 30px 0;
}

.convert_select_area {
    background: #2ca876;
    border-radius: 6px;
    padding: 15px;
}

.con_select_panel {
    border: 1px dashed #fff;
    border-radius: 6px;
    padding: 15px;
    height: 300px;
    position: relative;
}
.download-btn{
    @media (max-width: 467px) {
        margin-top: 20px;
    }
}

.slct_panel_content {
    position: absolute;
    top: 50%;
    left: calc(50% - 0px);
    transform: translate(-50%, -50%);
    text-align: center;
    pointer-events: none;
}

.drg_input {
    height: 100%;
    width: 100%;
    opacity: 0;
}

.drg_title {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
}

.drag_text {
    display: inline-block;
    color: white;
    /* white-space: nowrap; */
}


.file_lnk { 
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    text-align: center;
    position: relative;
    padding: 10px;
    display: inline-block;
    left: 50%;
     top: -22px; 
    transform: translate(-50%, 0);
    cursor: pointer;
}

.file_lnk svg {
    margin-top: -5px;
}

.clouds_icn {
    display: inline-block;
}

.clouds_icn svg {
    margin-top: -10px;
}

.url_bx_overlay {
    background: rgba(44, 168, 118, 1);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* display: none; */
    z-index: 9;
    border-radius: 6px;
}

.url_bx_overlay .url_txt_bx {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: #fff;
}

.url_txt_bx input {
    max-width: 500px;
    margin: 0 auto;
}


.url_txt_bx h3 {
    font-size: 24px;
}

.url_txt_bx h3 svg {
    height: 18px;
    width: 18px;
    margin-top: -2px;
}

.url_dismiss {
    position: absolute;
    top: 25px;
    left: 20px;
    cursor: pointer;
}

.url_dismiss img {
    width: 24px;
}

.file_draging {
    background: #46876c;
}

.convert_btn {
    padding: 8px 18px;
    background: #333333;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
}

.convert_btn:disabled {
    padding: 8px 18px;
    background: #333333;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
}

.convert_btn:hover {
    background: #2ca876;
    color: #fff;
}

.convert_btn svg {
    width: 20px;
    height: auto;
    margin-left: 5px;
}

/* Gradient Generator */

.gc_clr input {
    height: 120px;
}

.gc_clr_result {
    height: 250px;
    margin: 30px 0;
}

.gc_result_msg {
    text-align: center;
}

.gc_result_msg h5 {
    font-size: 18px;
}

.gc_result_msg h2 {
    font-size: 24px;
    font-weight: 550;
}

.copy_btn {
    padding: 8px 18px;
    background: #2ca876;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
}

.copy_btn:hover {
    background: #333333;
    color: #FFF;
}


/* Paragraph Generator */

.paragraph_bx {
    border: 1px solid #ced4da;
    border-radius: 6px;
    overflow: hidden;
    max-width: 900px;
    margin: 0 auto;
}

.para_head {
    padding: 2px;
    background: #2ca876;
}

.paras_body {
    min-height: 300px;
}

/* .paragraph_bx .paras_body textarea {
    min-height: 300px;
} */

.gp_btn_lst {
    display: flex;
    flex-wrap: wrap;
}

.gp_btn_lst button {
    padding: 7px 16px;
}

.gp_btn_lst button:hover {
    background: #FFF;
    color: #333333;
}

.gp_btn_lst input {
    height: 40px;
    width: 150px;
}

.gp_btn_lst select {
    height: 40px;
    width: 150px;
}

.pg_slct {
    position: relative;
}

.pg_slct .lng_icn {
    top: 8px;
}

.pg_slct .lng_icn svg {
    width: 14px;
    height: auto;
}

.paras_body textarea {
    height: 100%;
    border: 0;
}

.paras_body textarea:focus {
    box-shadow: 0 0 0 0.25rem rgb(44 168 118 / 25%);
    border-color: #2ca876;
}

/* Color Mixer Genreator */

.colorMix .color_mixer {
    margin-top: 60px;
}

.colorMix .start_color .icon_div {
    position: absolute;
    transition: all 0.25s ease 0s;
    bottom: 8px;
    left: 16px;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    box-shadow: rgb(71 75 255 / 8%) 0px 2px 3px;
    pointer-events: visible;
    z-index: 2;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}


.colorMix .start_color input:focus {
    border: 1px solid rgba(71, 75, 255, 0.5);
    box-shadow: rgb(71 75 255 / 20%) 0px 0px 0px 2px;
    background: rgb(255, 255, 255);
    transition: all 0.25s ease 0s;
}


.colorMix .start_color input:focus-visible {

    outline: none;
}

.colorMix .start_color .myinput {
    width: 100%;
    height: 56px;
    background: rgb(255, 255, 255);
    color: rgb(38, 39, 75);
    border: 1px dashed #333333;
    border-radius: 8px;
    padding: 16px 16px 0px 48px;

}

.colorMix .start_color label {
    position: absolute;
    top: -4px;
    left: 16px;
    height: 24px;
    line-height: 25px;
    font-size: 12px;
    color: rgb(143, 143, 166);
    display: flex;

    align-items: center;
}

.colorMix .start_color,
.colorMix .label_text {
    position: relative;
    width: 100%;
}

.colorMix .color_mixer {
    margin-top: 60px;
}

.colorMix .start_color {
    box-sizing: border-box;
    margin: 0px 0px 12px;
    min-width: 0px;
    flex: 2 1 0%;
    padding-left: 0px;
    padding-right: 0px;
}

.colorMix .CirclePicker_picker {
    background-color: white;
    border-radius: 8px;
    display: inline-block;
    padding: 8px;
}

.colorMix .color_picker {
    padding: 40px 20px;
}

.colorMix .customdesign_picker {
    margin: 40px 0px;
}


.colorMix .pattern {
    margin: 0 5%;
    height: 122px;
    padding-top: 15px;
    margin-top: 110px;
    clear: both;
}

.colorMix .demobox {
    background: #eee;
    margin-left: 1px;
    height: 100%;
    width: 107px;
    float: left;
    position: relative;
}

.colorMix .demobox:hover::before {
    opacity: 1;
}

.colorMix .demobox::before {
    content: attr(data-color);
    display: block;
    width: 100%;
    height: 100%;
    line-height: 107px;
    background: rgba(0, 0, 0, 0.1);
    transition: 0.2s opacity;
    color: white;
    text-align: center;
    text-transform: uppercase;
    opacity: 0;
    font-weight: bold;
    overflow: hidden;
    font-size: 12px;
}

.clr_panel_h {
    height: 70px;
}

/* Number Generator */

.number_generator {
    max-width: 900px;
    margin: 0 auto;
}




/*  LOADER */

.custom_Loader {
    position: fixed;
    /* or absolute */
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.custom_Loader .snurra {
    filter: url(#gegga);
}

.custom_Loader .stopp1 {
    stop-color: #2ca876;
}

.custom_Loader .stopp2 {
    stop-color: #707375;
}

.custom_Loader .halvan {
    animation: Snurra1 10s infinite linear;
    stroke-dasharray: 180 800;
    fill: none;
    stroke: url(#gradient);
    stroke-width: 23;
    stroke-linecap: round;
}

.custom_Loader .strecken {
    animation: Snurra1 3s infinite linear;
    stroke-dasharray: 26 54;
    fill: none;
    stroke: url(#gradient);
    stroke-width: 23;
    stroke-linecap: round;
}

.custom_Loader .skugga {
    filter: blur(5px);
    opacity: 0.3;
    position: absolute;
    transform: translate(3px, 3px);
}

@keyframes Snurra1 {
    0% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: -403px;
    }
}

/* //// LOADER TWO */
.loading {
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    /* background: #1a1a1a; */
}

@keyframes loader-out {
    100% {
        visibility: hidden;
        opacity: 0;
    }
}

.loading .loaded {
    animation: loader-out 0.3s both;
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #2ca876 #2ca876 transparent transparent;
    box-sizing: border-box;
    scale: 1.5;
    animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #FCB040 #FCB040;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
}

.loader::before {
    width: 32px;
    height: 32px;
    border-color: #2ca876 #2ca876 transparent transparent;
    animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

/*Vertical Flip*/

.verticalFlip {
    display: inline;
}

.verticalFlip span {
    animation: vertical 10.5s linear infinite 0s;
    -ms-animation: vertical 10.5s linear infinite 0s;
    -webkit-animation: vertical 10.5s linear infinite 0s;
    color: #ea1534;
    opacity: 0;
    overflow: hidden;
    position: relative;
    margin-left: 10px;
}

.verticalFlip span:nth-child(2) {
    animation-delay: 2.5s;
    -ms-animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
    top: -36px;
}

.verticalFlip span:nth-child(3) {
    animation-delay: 5s;
    -ms-animation-delay: 5s;
    -webkit-animation-delay: 5s;
    top: -72px;
}


/* .lst_txt {
    margin-right: 200px;
} */


/* .verticalFlip span:nth-child(4) {
    animation-delay: 7.5s;
    -ms-animation-delay: 7.5s;
    -webkit-animation-delay: 7.5s;
}

.verticalFlip span:nth-child(5) {
    animation-delay: 10s;
    -ms-animation-delay: 10s;
    -webkit-animation-delay: 10s;
} */


/* Responsiveness */

@media (min-width: 1024px) {
    .static_page .sidenav {
        display: none;
    }
}

@media (max-width: 1199px) {
    .tp_ul li {
        margin-left: 10px;
    }
}

@media (max-width: 1023px) {
    .app_other_links {
        display: none;
    }

    .small_view_head {
        display: flex;
    }

    .small_view .small_view_head {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 7px;
        padding: 4px 0;
    }

    .tp_search {
        max-width: unset;
    }

    .sidenav {
        left: -265px;
        transition: 0.3s;
    }

    .content_wrapper {
        margin-left: 0;
    }

    .main_panel {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .login_right_col {
        order: -1;
    }

    .login_left img {
        display: none;
    }

    .contact_uss h2 {
        margin-bottom: 10px;
    }

    .guest_form {
        margin-top: 30px;
    }

    .slct_panel_content {
        left: 50%;
        width: 200px;
    }
}

@media (max-width: 767px) {}



@media (max-width: 575px) {
/* this was added by ghous to mnnage mobile screen size */
textarea {
    height: 132px !important;

}

    .app_top_nav {
        /* flex-direction: column;
        justify-content: center;
         */
         flex-direction: row;
    justify-content: space-between;
        padding: 18px 10px;
    }

    .app_logo {
        width: auto;
    }

    .app_logo img {
        width: 170px;

    }

    .small_view .small_view_head {
        margin-left: unset;
        
    }

    .sidenav {
        top: 78px;
    }

    .content_wrapper {
        margin-top: 54px;
    }

    .login_panel {
        padding: 30px 15px;
    }

    .login_right {
        padding: 50px 15px;
    }

    .login_left {
        padding: 30px 15px;
    }

    .search_bx_overlay .tp_search {
        width: 90%;
    }

    .search_bx_overlay input {
        max-width: 100%;
    }

    .file_lnk {
        padding: 0;
    }

    .home_page {
        min-height: calc(100vh - 87.5px);
      
    }

    .drg_title {
        font-size: 14px;

    }

    .drag_text {
        font-size: 12px;
/* this was added by ghous to manage mobile screen size */
        text-wrap: balance;
    }

    .convert_btn {
        font-size: 14px;
      }
      .convert_btn:disabled {
    
        font-size: 14px;
    }

    .pg_title h2 {
        font-size: x-large;
    }


}

@media (max-width: 375px) {
    .file_lnk {
        letter-spacing: -1px;
    }
}


/* Animation Code */


/*Vertical Flip Animation*/

@-moz-keyframes vertical {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -moz-transform: rotateX(180deg);
    }

    10% {
        opacity: 1;
        -moz-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -moz-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -moz-transform: translateY(0px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes vertical {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -webkit-transform: rotateX(180deg);
    }

    10% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -webkit-transform: translateY(0px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes vertical {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -ms-transform: rotateX(180deg);
    }

    10% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -ms-transform: translateY(0px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

/* // 
// _dropdown.scss
//  */
/* Dropdown Toggle After */
.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    font-size: 15px;
    line-height: 15px;
    content: "\F0140";
    font-family: "Material Design Icons";
}

/* Dropdown Menu */
.dropdown-menu {
    /* Replace $box-shadow-lg with your desired shadow value */
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    position: absolute;
    z-index: 1000;
}

/* Dropdown Megamenu */
.dropdown-menu.dropdown-megamenu {
    padding: 20px;
    left: 0 !important;
    right: 0 !important;
}

/* Dropdown Slide Animation */
@keyframes DropDownSlide {
    100% {
        margin-top: -1px;
    }

    0% {
        margin-top: 8px;
    }
}

/* Active Background Color */
.dropdown-item.active,
.dropdown-item:active {
    background-color: #bfbfbf; /* Replace with your desired color */
    color: #fff; /* Optional: Change text color */
}

/* /// CARD CSS */

.table th {
    font-weight: 600;
}
.card-title {
    font-size: 16px;
    margin: 0 0 7px 0;
}

.card {
    margin-bottom: 1.5rem;
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    border-color: aliceblue;
}

.card-header {
    border-bottom: 1px solid var(--vz-border-color);
    
}
.card-footer {
    border-top: 1px solid var(--vz-border-color);
}

/* // avatar */
.rounded-circle {
    border-radius: 50% !important;
}
/* .avatar-lg {
    height: 6rem;
    width: 6rem;
} */

.avatar-title {
  
    align-items: center;
    background-color: #695eef;
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.avatar-md {
    height: 5.5rem;
    width: 5.5rem;
}
.avatar-xs {
    height: 2rem;
    width: 2rem;
}

/* // TEXT */
.fs-13 {
    font-size: 13px !important;
}
.fs-14 {
    font-size: 14px !important;
}
.text-muted {
    --vz-text-opacity: 1;
    color: #878a99 !important;
}

/* /// Custom scroll */


[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto; /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  padding: 0px !important;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 6px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll {
  height: 100%;
}


/* // 
// _dropdown.scss
//  */
.topbar-user .dropdown-menu {
    top: 6px !important;
}


.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    font-size: 15px;
    line-height: 15px;
    content: "\F0140";
    font-family: "Material Design Icons";
}

@keyframes DropDownSlide {
    100% {
        margin-top: -1px;
    }

    0% {
        /* margin-top: 8px; */
        /* Consider using other properties instead of margin-top */
        top: 8px; /* Example using top */
        /* or */
        transform: translateY(8px); /* Example using transform */
    }
}

.dropdown-menu {
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    animation-name: DropDownSlide;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    position: absolute;
    z-index: 1000;
}

.dropdown-menu.dropdown-megamenu {
    padding: 20px;
    left: 0 !important;
    right: 0 !important;
}

.dropdown-menu[data-popper-placement="top-start"] {
    animation-name: DropDownSlideDown;
}


@keyframes DropDownSlideDown {
    100% {
        margin-bottom: 0;
    }

    0% {
        bottom: 8px; /* Example using top */
        /* or */
        transform: translateY(8px); /* Example using transform */
    }
}

@media (min-width: 600px) {
    .dropdown-menu-xl {
        width: 420px;
    }

    .dropdown-menu-lg {
        width: 320px;
    }

    .dropdown-menu-md {
        width: 240px;
    }
}

/* Ancher Tag */
.page_body a {
    text-decoration: none;
}

/* MODAL */
.modal  input {
    background: #f4f4f4;
    height: 52px;
    border: 0;
    padding: 10px 20px;

    &:focus {
      box-shadow: unset;
      border: 1px solid #2ca876;
    }

    &::placeholder {
      color: #acacaf;
    }
  
}

.modal  textarea {
    background: #f4f4f4;
    height: 120px;
    border: 0;
    padding: 10px 20px;

    &:focus {
      box-shadow: unset;
      border: 1px solid #2ca876;
    }

    &::placeholder {
      color: #acacaf;
    }
  
}


.modal label {
    margin-bottom: 0px;
}

.connect_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .social_icons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 5px;
  }
  

  
  .icon_link {
    text-decoration: none; /* Remove underline decoration */
  }
  
  .instagram_icon {
    color: #e1306c; /* Instagram theme color */
  }
  
  .facebook_icon {
    color: #3b5998; /* Facebook theme color */
  }
  
  .youtube_icon {
    color: #ff0000; /* YouTube theme color */
  }

  .tiktok_icon {
    color: #f4f4f4;
  }