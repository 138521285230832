/* blogs_details */


/* .blogs_details{
  margin: 70px 0;
} */

.blogdetails {
    input {
        background: #f4f4f4;
        height: 52px;
        border: 0;
        padding: 10px 20px;
    
        &:focus {
          box-shadow: unset;
          border: 1px solid #2ca876;
        }
    
        &::placeholder {
          color: #acacaf;
        }
      }
}

.blogs_details h2 {
    font-size: 36px;
    font-weight: 550;
    color: #222629;
    margin-bottom: 0;
    max-width: 690px;
}

.blg_dls_meta {
    font-size: 18px;
    color: #222629;
    font-weight: 500;
    margin-top: 10px;
}

.blg_dls_meta span {
    color: #a2a4a5;
}

.blg_dls_meta img {
    max-width: 40px;
}

.blg_d_media li {
    display: inline-block;
    margin-right: 15px;
}

.blg_d_media li a {
    font-size: 14px;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    display: block;
}

.blg_d_media li a span {
    margin-right: 3px;
}

.blg_d_media li a:hover {
    opacity: 0.7;
    transition: 0.3s;
    color: #fff;
}

.blg_d_media li a i {
    font-size: 18px;
}

.blg_d_media li a svg {
    height: 16px;
    width: auto;
}

.fb_meta {
    background: #3170be;
}

.tw_meta {
    background: #55acee;
}

.pin_meta {
    background: #cb1f27;
}

.envel_meta {
    background: #6b6e70;
}

.ellip_meta i {
    color: #000000;
    font-size: 22px;
}

.bd_p {
    color: #222629;
    font-size: 16px;
}

.bd_img {
    text-align: center;
    margin: 40px 0;
}

.bd_img img {
    max-width: 767px;
    width: 100%;
    margin: 0 auto;
}