@charset "utf-8";

/* CSS Document */



.workingPoints_list li{
    display: flex;
    align-items: baseline;
}

.list_ck_icn{
    margin-right: 10px;
}

.yt_mini_pst img{
    width: 100%;
}

.about_rgt_cont p{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.reasonable_mrgn{
    margin: 30px 75px;
}

.services_footer_tool{
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
}

.gen_row{
    display: flex;
    justify-content: space-between;
}

.drag_printable{
    width: calc(100% - 330px);
}

.manage_pos{
    position: relative;
    left: calc(50% + 165px);
    transform: translateX(-50%);
}

#decisions {
    position: relative;
    left: calc(-50% - 165px);
    transform: translateX(50%);
}

.contractpolicy .btn-pick{
    background-color: #333333;
    border-color: unset;
}



.dragableHeading {
    animation: blinker 1s step-end infinite;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .topbar-user i{
    color: #bfbfbf;
    font-size: 20px;
  }

  .register_icn{
    font-size: 18px;
    position: relative;
    top: 2px;
  }

  .topbar-user .ri-arrow-down-s-fill{
    position: relative;
    top: 3px;
  }

  .search_icn i{
    color: #bfbfbf;
  }

  .ssearch_icn i{
    font-size: 22px;
  }

  .app_tools_itm_cont p span i{
    font-size: 16px;
  }

  .topbar-user.dropdown {
    margin-top: -6px;
}

.topbar-user .ri-account-circle-line{
    position: relative;
    top: 3px;
}

.convert_btn .ri-arrow-right-line{
    margin-left: 10px;
}

.clouds_icn i{
    font-size: 28px;
    color: #FFF;
    margin-left: 10px;
}

.about_rgt_cont {
    text-align: center;
}

.team_bxs .avatar-md{
    height: 150px;
    width: 150px;
}

.team_bxs .avatar-md img{
    border: 2px dashed #2ca876;
}

.customer_img_bx{
    background: rgba(0, 0, 0, 0.1);
}

.file_lnk{
    background: #333333;
    border-radius: 4px;
    top: -45px;
    padding: 8px 18px;
}

.file_lnk:hover{
    background: #666666;
}

.file_lnk i{
    margin-right: 5px;
}

  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

@media (max-width:991px){
    .home_page{
        background: #f6f5fa !important;
    }
    .videos_posts .yt_post_feature{
        background-position: center !important;
        min-height: 315px;
        margin-bottom: 30px;
    }
    
}

@media (max-width: 767px){
    .reasonable_mrgn{
        margin: 30px 50px;
    }
    .gen_row{
        flex-direction: column;
    }
    .drag_printable{
        width: 100%;
    }

    .contractpolicy #vars-menu{
        width: 100% !important;
    }

    .manage_pos{
        position: unset;
        left: unset;
        transform: unset;
    }
    
    #decisions {
        position: unset;
        left: unset;
        transform: unset;
    }
}

@media (max-width: 575px){
    .home_page {
        min-height: calc(100vh - 54px);
    }

    .reasonable_mrgn{
        margin: 30px;
    }
}

