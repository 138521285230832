.design {

    

    .avatar-sm {
        height: 3rem;
        width: 3rem;
    }

    .avatar-title {
        align-items: center;
    background-color: #695eef;
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    width: 100%;
    }

}  