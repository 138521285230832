/* Blogs */
.blog {
  .card {
    margin-bottom: 1.5rem;
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    border-color: aliceblue;
}

.card-animate{
  transition: all 0.4s;

  &:hover{
    transform: translateY(calc(-1.5rem / 5));
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  }

}
  .blog_pst_img_w {

    
  
      margin-bottom: 30px;
      
      .blog_featre_img {
      background: url(../../images/bolg_feature_img.png) no-repeat;
      background-size: cover;
      height: calc(100% - 30px);
      position: relative;
      margin-bottom: 30px;
      }
      
      .blog_pst_img {
      position: relative;
      margin-bottom: 5px;
      cursor: pointer;
      border-radius: 7px;
      overflow: auto;
      
   
      img {
        width: 100%;
      }
      }
      
      .pst_meta {
  
      
   
      span {
        font-size: 14px; 
        i {
          font-size: 14px;
          margin-right: 5px;
        }
      }
      }
      
      .blg_pst_overlay {
      position: absolute;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      left: 0;
      }
      }
      
      .sp_img {
      margin-right: 10px;
      min-width: 70px;
      
      img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      }
      }
      
      .sp_meta {
      i {
      font-size: 14px;
      margin-right: 5px;
      }
      
      span {
      font-size: 14px;
      }
      
      p {
      font-size: 14px;
      font-weight: 550;
      line-height: 16px;
      }
      }
      
      .sps_list li {
      display: flex;
      align-items: flex-start;
      padding: 7px 0;
      border-bottom: 1px solid #dee2e6;
      cursor: pointer;
      width: fit-content;
      
      &:last-child {
      border-bottom: 0;
      }
      }
}
