/* Guest Post */


.guest_post {



  input {
    background: #f4f4f4;
    height: 52px;
    border: 0;
    padding: 10px 20px;

    &:focus {
      box-shadow: unset;
      border: 1px solid #2ca876;
    }

    &::placeholder {
      color: #acacaf;
    }
  }

  margin-top: 40px;
  .guest_post {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .guest_terms {
    background: #2ca876;
    border-radius: 6px;
    padding: 15px;
    height: 100%;
    h2 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 12px;
      color: #fff;
      line-break: anywhere;
    }
    p {
      color: #333333;
      font-size: 22px;
    }
  }
  .guest_form {
    background: #333333;
    border-radius: 6px;
    padding: 15px;
    h2 {
      color: #fff;
      font-size: 32px;
      font-weight: bold;
    }
    form {
      input {
        height: 40px;
      }
      select {
        height: 40px;
        font-size: 14px;
      }
    }
    .lng_icn {
      top: 33px;
      svg {
        height: 7.5px;
        width: auto;
        pointer-events: none;
      }
    }
    .input_holder_lng {
      label {
        font-size: 14px;
        border-radius: 2px;
      }
    }

  }
  .specify_other {
    input {
      &::-webkit-input-placeholder {
        color: #acacac;
      }
    }
  }
  .input_holder_lng {
    position: relative;
    text-align: left;
    margin-top: -10px;
    input {
      height: 50px;
      padding: 5px 20px;
      padding-right: 35px;
      &:focus {
        box-shadow: none;
        border-color: #2ca876;
      }
    }
    &::-webkit-input-placeholder {
      color: #acacac;
      font-size: 16px;
      font-weight: 300;
    }
    label {
      position: relative;
      text-align: left;
      font-size: 16px;
      font-weight: 300;
      background: #fff;
      top: 14px;
      left: 10px;
      padding: 0 10px;
      color: #acacac;
    }
  }
  .lng_icn {
    position: absolute;
    top: 36px;
    right: 13px;
    pointer-events: none;
  }
  .validate_lbl {
    font-size: 14px;
    color: #ff2828;
    text-align: left;
    display: inherit;
    margin-left: 15px;
    margin-top: 1px;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    opacity: 0;
  }
  .placeholder_txt {
    input {
      &::-webkit-input-placeholder {
        font-size: 32px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .rmbr_me {
    font-size: 14px;
    font-weight: 300;
    color: #acacac;
    margin-top: -10px;
    label {
      display: flex;
      align-items: center;
    }
    div {
      margin-top: 3.5px;
      margin-left: 5px;
    }
  }
  
}

.submit_btn {
  button {
    &:hover {
      background: #333333;
      color: #fff;
    }
    color: #fff;
    background: #2ca876;
    padding: 10.5px;
    font-size: 18px;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    margin-top: 20px;
  }
}