/* ////** PAGE NOT FOUND /// */

.not_found {
  min-width: 150px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h2 {
    margin-bottom: 0.5rem;
  }
}
