/* About Page */

.about_lft_cont img {
  height: auto;
}

.about_rgt_cont {
  h2 {
    color: #333333;
    font-size: 36px;
    font-weight: bold;
  }

  p {
    color: #333333;
    font-size: 16px;
    font-weight: normal;
  }
}

.aboutus_btn {
  padding: 7px 16px;
  background: #2ca876;
  color: #fff;
  font-size: 16px;
  display: inline-block;
  text-decoration: none;

  &:hover {
    background: #333333;
    color: #fff;
  }

  transition: all 0.4s ease-in-out;

  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

/* Youtube Videos */

.videos_posts {
  // margin-top: 40px;
  margin-bottom: 10px;

  .pst_meta {

    
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .yt_post_feature {
    background: url(../../images/yt_featuret_img.png) no-repeat;
    background-size: cover;
    height: calc(100% - 25px);
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    .pst_meta {
      p {
        font-size: 18px;
        font-weight: 500;
      }
    }

    .yt_ply_btn {
      font-size: 12px;
      width: 36px;
      height: 36px;
      line-height: 36px;
    }
  }

  .pst_overlay {
    position: absolute;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 30%
    );
    width: 100%;
    height: 100%;
    left: 0;
  }

  .yt_mini_pst {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;

  }

  .yt_ply_btn {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #ff0000;
    display: inline-block;
    position: absolute;
    top: 10px;
    z-index: 1;
    left: 10px;
    text-align: center;
    color: #fff;
    font-size: 10px;
    line-height: 23.5px;

    svg {
      margin-left: 1.5px;
      height: 10px;
      width: auto;
    }
  }

  .yt_post_feature .yt_ply_btn {
    svg {
      margin-left: 1.5px;
      height: 14px;
      width: auto;
    }
  }
}

