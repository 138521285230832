/* Contact Us */
.contact_uss {
    margin-top: 10px;
    margin-bottom: 0;
  
    h2 {
      color: #222629;
      display: inline-block;
      text-align: center;
      margin-bottom: 30px;
      padding-bottom: 5px;
      font-size: 36px;
      font-weight: bold;
    }
  
    .contact_left_p {
      margin-bottom: 30px;
  
      .cont_info_t {
        display: flex;
        align-items: baseline;
  
        span {
          min-width: 40px;
          font-size: 20px;
          text-align: center;
  
          i {
            color: #000;
          }
        }
  
        p {
          color: #acacaf;
          font-size: 16px;
        }
      }
    }
  
    .map_holder {
      iframe {
        min-height: 210px;
        border-radius: 10px;
      }
    }
  
    .contact_right_p {
      margin-bottom: 30px;
  
      input {
        background: #f4f4f4;
        height: 52px;
        border: 0;
        padding: 10px 20px;
  
        &:focus {
          box-shadow: unset;
          border: 1px solid #2ca876;
        }
  
        &::placeholder {
          color: #acacaf;
        }
      }
  
      textarea {
        background: #f4f4f4;
        padding: 10px 20px;
  
        &::placeholder {
          color: #acacaf;
          resize: none;
        }
  
        &:focus {
          box-shadow: 0 0 0 0.25rem rgb(44 168 118 / 25%);
          border-color: #2ca876;
        }
      }
  
      button {
        background: #2ca876;
        color: #fff;
        border-radius: 6px;
        padding: 10px;
        font-size: 18px;
        transition: all 0.4s ease-in-out;
  
        &:hover {
          background: #333333;
          color: #fff;
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }