/* Home Services */

.home_page {
  /* background: #e7e7e7; */
  // background: url(../../images/home_bg.png) no-repeat;
  background-size: 100% 100%;
  margin: -15px;
  padding: 70px 0;
  min-height: calc(100vh - 75px);

  .pg_title {
    margin-top: 40px ;

    h2 {
      span {
        color: #fff;
        background: #fcb040;
        padding-top: 4px;
        padding-bottom: 0;
        padding-left: 2px;
        padding-right: 2px;
      }
    }

    h1 {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      position: relative;
      max-width: 355px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.app_tools_itm_icn {
  display: flex;
  align-items: baseline;
  position: relative;

  &::after {
    content: "";
    background: #2ca876;
    position: absolute;
    bottom: 12px;
    left: 25px;
    height: 40px;
    width: 40px;
    display: table;
    border-radius: 4px;
    box-shadow: 0px 5px 9.8px 0.2px rgba(0, 0, 0, 0.3);
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }

  svg {
    height: 70px;
    width: 70px;
    position: relative;
    z-index: 1;

    g {
      fill: #333333;
    }
  }
}

.app_tools_item {
  position: relative;
  background: #fff;
  box-shadow: 0px 5px 9.8px 0.2px rgba(102, 102, 102, 0.15);
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #e7e7e7;
  overflow: hidden;
  transition: all 0.4s ease-in-out;

  &.-webkit-transition {
    all: 0.4s ease-in-out;
  }

  &.-moz-transition {
    all: 0.4s ease-in-out;
  }

  &.-o-transition {
    all: 0.4s ease-in-out;
  }

  .itms_count {
    background: #ee4036;
    border-radius: 15px;
    padding: 2px 10px;
    padding-bottom: 0;
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: auto;
    z-index: 1;
    color: #fff;
  }

  .app_tools_itm_cont {
    p {
      font-size: 14px;
      color: #333333;
      margin-bottom: 10px;
    }

    span {
      cursor: pointer;
      display: block;
      text-align: right;

      svg {
        height: 13px;
        width: auto;

        g {
          fill: #2ca876;
        }
      }
    }
  }
}

.featured_itm {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px;
  padding-bottom: 5px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    font-size: 11px;
    margin-bottom: 0;
    color: #707375;
    letter-spacing: 0.2px;
  }

  span {
    font-size: 10px;
    color: #2ca876;
    font-weight: 500;
    cursor: pointer;
  }
}

/* TOOL CARD HOVER */

.app_tools_item {
    &:hover {
      background: #2ca876;
      transition: all 0.4s ease-in-out;
      &:hover {
        .app_tools_itm_icn svg g {
          fill: #fff;
        }
        .app_tools_itm_icn h4 {
          color: #fff;
        }
        .app_tools_itm_cont p {
          color: #fff;
        }
        .app_tools_itm_icn::after {
          background: #fcb040;
        }
        .app_tools_itm_cont svg g {
          fill: #fff;
          background: #2ca876;
          transition: all 0.4s ease-in-out;
          &:hover {
            fill: #ee4036;
            background: #2ca876;
            transition: all 0.4s ease-in-out;
          }
        }
      }
    }
  }
  
  .home_page {
    .owl-theme {
      .owl-nav {
        margin-top: 50px;
      }
    }
    .owl-carousel {
      .owl-nav {
        button.owl-next, button.owl-prev {
          font-size: 27px;
          height: 24px;
          line-height: 20px;
          width: 24px;
          border-radius: 50%;
          position: relative;
          background: #2ca876;
          color: #fff;
          transition: all 0.4s ease-in-out;
          &:hover {
            background: #333333;
            transition: all 0.4s ease-in-out;
          }
        }
        button.owl-prev:hover {
          background: #333333;
        }
      }
    }
  }
  
  .counter_wrap {
    position: relative;
    .counter {
      position: absolute;
      bottom: 40px;
      right: 0;
      color: #ee4036;
      font-weight: 600;
    }
  }
  
  
  @media (max-width:575px) {
    .home_page { padding: 28px 0px;}
   
  }